// tslint:disable-next-line: no-var-requires
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import Vue from 'vue';
import Component from 'vue-class-component';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import FileModalComponent from '@/components/file-modal/file-modal.component';
import JQuery from 'jquery';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';
import SeriesSectionCollageComponent from '@/components/series-section-collage/series-section-collage.component';
const $ = JQuery;
// import 'owl.carousel'

const components: any = {
  DunatiLoaderComponent,
  FileModalComponent,
  SeriesSectionCollageComponent
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const LightBox = () => import('vue-image-lightbox');
  // @ts-ignore
  const carousel = () => import('vue-owl-carousel');
  components.carousel = carousel;
  components.LightBox = LightBox;
}

@Component({
  components,
})
export default class SerieNordikaPage extends Vue {

  public scrollable = true;
  public autoplay = true;
  public overlayColor = null; // ej: "#000000"
  public  overlayTransparency = 50; // 0 al 100
  public picturesViewer: 'carousel' | 'collage' = 'carousel';

  public carouselResponsive = {
    320: {
      items: 1,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: this.autoplay,
      autoplayHoverPause: true,
    },
    767: {
      items: 2,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: this.autoplay,
      autoplayHoverPause: true,
    },
    992: {
      items: 3,
      nav: true,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: false,
    },
    1140: {
      items: 4,
      nav: true,
      autoplay: false,
    },
  };
  public listCarouse = [
    { img: 'oficina-gerencial-moderna-legacy-02.jpg' },
    { img: 'oficina-gerencial-moderna-legacy-05.jpg' },
    { img: 'sala-reunion-mesa-reuniones-legacy-poltronas-lucia.jpg' },
    { img: 'sala-mesa-reunion-poltrona-lucia-legacy.jpg' },
    { img: 'oficina-escritorio-gerencial-silla-midas.jpg' },
    { img: 'oficina-gerencial-moderna-legacy.jpg' },
    { img: 'oficina-gerencial-moderna-legacy-03.jpg' },
    { img: 'Sofas-y-mesas-para-oficinas.jpg' },
  ];
  public images = [
    { src: '/assets/images/series/serie_legacy/oficina-gerencial-moderna-legacy-02.jpg' },
    { src: '/assets/images/series/serie_legacy/oficina-gerencial-moderna-legacy-05.jpg' },
    { src: '/assets/images/series/serie_legacy/sala-reunion-mesa-reuniones-legacy-poltronas-lucia.jpg' },
    { src: '/assets/images/series/serie_legacy/sala-mesa-reunion-poltrona-lucia-legacy.jpg' },
    { src: '/assets/images/series/serie_legacy/oficina-escritorio-gerencial-silla-midas.jpg' },
    { src: '/assets/images/series/serie_legacy/oficina-gerencial-moderna-legacy.jpg' },
    { src: '/assets/images/series/serie_legacy/oficina-gerencial-moderna-legacy-03.jpg' },
    { src: '/assets/images/series/serie_legacy/Sofas-y-mesas-para-oficinas.jpg' },
  ];

  // ver serie serie-training-page para ejemplo
  public collageBackground = null;
  public collage  = [];

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Escritorios Serie Legacy - Muebles de oficina | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Legacy propone escritorios para oficinas modernas. El diseño de su estructura refiere una base rústica industrial, sin perder la elegancia y el orden que una oficina necesita.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/escritorios/legacy`,
        },
        {
          property: 'og:title',
          content: 'Escritorios Serie Legacy - Muebles de oficina | Dunati',
        },
        {
          property: 'og:description',
          content: 'Legacy propone escritorios para oficinas modernas. El diseño de su estructura refiere una base rústica industrial, sin perder la elegancia y el orden que una oficina necesita.',
        },
        {
          property: 'og:image',
          content: `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/escritorios/legacy`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/escritorios/diamond`
          }
        ]
      }
      */
    };

    this.$options.dunatiJsonld = [
      DUNATI_ORGANIZATION_SCHEMA,
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
           {
              "@type": "ListItem",
              "position": 1,
              "item": {
                 "@id": DUNATI_DOMAIN,
                 "name": "Inicio"
              }
           },
           {
              "@type": "ListItem",
              "position": 2,
              "item": {
                 "@id": `${DUNATI_DOMAIN}/productos`,
                 "name": "Productos"
              }
           },
           {
            "@type": "ListItem",
            "position": 3,
            "item": {
               "@id": `${DUNATI_DOMAIN}/escritorios`,
               "name": "Escritorios"
            }
          },
           {
              "@type": "ListItem",
              "position": 4,
              "item": {
                "@id": `${DUNATI_DOMAIN}/escritorios/legacy`,
                "name": "Legacy"
              }
           }
        ]
      }
    ];
  }

  public mounted() {
    window.addEventListener('touchmove', this.preventBehavior, { passive: false });
  }

  public destroyed() {
    window.removeEventListener('touchmove', this.preventBehavior);
  }

  public updated() {
    this.$nextTick(() => {
      $('#carouselImg .owl-carousel .owl-item img').click((el) => {
        this.showImgSlider((el.target.dataset.index) ? +el.target.dataset.index : 0);
      });

      const owl = $('#carouselImg .owl-carousel');

      owl.on('drag.owl.carousel', (event) => {
        document.ontouchmove = (e) => {
          e.preventDefault();
        };
      });

      owl.on('dragged.owl.carousel', (event) => {
        document.ontouchmove = (e) => {
          return true;
        };
      });

    });
  }

  public showImgSlider(index: number) {
    if (typeof (index) === 'number') {
      // @ts-ignore
      if (this.$mq === 'sm' || this.$mq === 'xs') {
        return;
      }

      // @ts-ignore
      this.$refs.lightBox.showImage(index);
    }
  }

  public dragged() {
    $('body').css('overflow', 'auto');
    this.scrollable = true;
  }

  public drag() {
    if (window.innerWidth <= 768) {
      $('body').css('overflow', 'hidden');
    }
    this.scrollable = false;
  }

  public preventBehavior(e: any) {
    if (!this.scrollable) {
      e.preventDefault();
    }
  }

  public onLoaded(id: string) {
    const cls: string = `.${id}`;
    $(cls).hide();
  }

  public setText(option: string) {
    (this.$refs['file-modal'] as any).showModal(option, 'legacy', true);
  }

  public hex2rgba(hex: string, transparency: number = 100): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const alpha = 1 - (transparency/100);
    
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
}
